// tslint:disable-next-line:no-shadowed-variable
import { ConfigModel } from '../core/interfaces/config';

// tslint:disable-next-line:no-shadowed-variable
export class MenuConfig implements ConfigModel {
	public config: any = {};

	constructor() {
		this.config = {
			header: {
				self: {},
				items: []
			},
			aside: {
				self: {},
				items: [
					/*
					{
						title: 'Dashboard',
						desc: 'Some description goes here',
						root: true,
						icon: 'la la-globe',
						page: '/',
						//badge: {type: 'm-badge--danger', value: '2'},
						translate: 'MENU.DASHBOARD',
						roles: ['ADMIN', 'BUSINESS', 'STAFF', 'RIDER', 'USER']
					},
					{section: 'Sections'},
					*/
					{
						title: 'Utenti',
						desc: 'Some description goes here',
						root: true,
						icon: 'la la-users',
						page: '/users',
						translate: 'MENU.USERS',
						roles: ['ADMIN']
					},
					{
						title: 'Resoconti',
						desc: 'Some description goes here',
						root: true,
						icon: 'la la-calculator',
						page: '/reports',
						translate: 'MENU.REPORTS',
						roles: ['ADMIN']
					},
					{
						title: 'Documenti',
						desc: 'Some description goes here',
						root: true,
						icon: 'la la-file-text',
						page: '/documents',
						translate: 'MENU.DOCUMENTS',
						roles: ['ADMIN']
					},
					{
						title: 'Buste Paga',
						desc: 'Some description goes here',
						root: true,
						icon: 'la la-file-text',
						page: '/payslips',
						//translate: 'MENU.DOCUMENTS',
						roles: ['ADMIN']
					},
					{
						title: 'Comunicazioni',
						desc: 'Some description goes here',
						root: true,
						icon: 'la la-paper-plane',
						page: '/communications',
						translate: 'MENU.COMMUNICATIONS',
						roles: ['ADMIN']
					},
					{
						title: 'Agibilità',
						desc: 'Some description goes here',
						root: true,
						icon: 'la la-music',
						page: '/agibilities',
						translate: 'MENU.AGIBILITIES',
						roles: ['ADMIN']
					},
					{
						title: 'Fatture fornitori',
						desc: 'Some description goes here',
						root: true,
						icon: 'la la-sign-out',
						page: '/invoices-suppliers',
						translate: 'MENU.INVOICES_SUPPLIERS',
						roles: ['ADMIN']
					},
					{
						title: 'Fatture clienti',
						desc: 'Some description goes here',
						root: true,
						icon: 'la la-sign-in',
						page: '/invoices-customers',
						translate: 'MENU.INVOICES_CUSTOMERS',
						roles: ['ADMIN']
					},
				]
			}
		};
	}
}
