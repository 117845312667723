import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, forkJoin, of, BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { UsersModel } from '@app/core/models/users.model';
import { QueryParamsModel } from '@app/content/pages/components/apps/e-commerce/_core/models/query-models/query-params.model';
import { QueryResultsModel } from '@app/content/pages/components/apps/e-commerce/_core/models/query-models/query-results.model';
import { HttpUtilsService } from '@app/content/pages/components/apps/e-commerce/_core/utils/http-utils.service';

import { AuthenticationService } from '@app/core/auth/authentication.service';

import { API } from '@env/environment';

@Injectable()
export class UsersService {
	lastFilter$: BehaviorSubject<QueryParamsModel> = new BehaviorSubject(new QueryParamsModel({}, 'asc', '', 0, 10));

	private usersList: any[];

	constructor(private http: HttpClient, private httpUtils: HttpUtilsService, private auth: AuthenticationService) { }

	// CREATE =>  POST: add a new service to the server
	createUser(user: UsersModel): Observable<UsersModel> {
		const url = `${API.API_URL + API.API_VERSION + API.API_USERS}`;
		return this.http.post<UsersModel>(url, user);
	}

	// READ
	getUsers(): Observable<UsersModel[]> {
		const url = `${API.API_URL + API.API_VERSION + API.API_USERS}`;
		return this.http.get<UsersModel[]>(url).pipe(
			tap((result: any) => this.usersList = result)
		);
	}

	findUsers(queryParams: QueryParamsModel): Observable<QueryResultsModel> {
		const url = `${API.API_URL + API.API_VERSION + API.API_USERS}`;
		return this.http.get<QueryResultsModel>(url, {
            params: new HttpParams()
                .set('fullname', (queryParams.filter.fullname || ''))
                .set('page_index', queryParams.pageNumber.toString())
                .set('page_size', queryParams.pageSize.toString())
        }).pipe(
			tap((result: any) => { 
                if (result) {
                    this.usersList = result.data
                } else {
                    this.usersList = []
                }

                return this.usersList;
            })
		);
	}

	getUserById(userId: string): Observable<any> {
		const userFind = this.usersList.filter(user => user.id === userId);
		if (userFind.length > 0) {
			return of(userFind[0]);
		} else {
			return of({});
		}
	}

	getUserNameById(userId: string): string {
		const userFind = this.usersList.filter(user => user.id === userId);
		if (userFind.length > 0) {
			return userFind[0]['ds_fullname'];
		} else {
			return '';
		}
	}

	// UPDATE => PUT: update the user on the server
	updateUser(userId: string, user: UsersModel): Observable<any> {
		const url = `${API.API_URL + API.API_VERSION + API.API_USERS}/${userId}`;
		return this.http.put(url, user);
	}

	// ACTIVATE => activate the user from the server
	activateUser(userId: string): Observable<UsersModel> {
		const url = `${API.API_URL + API.API_VERSION + API.API_USERS}/${userId}/activate`;
		return this.http.put<UsersModel>(url, {});
	}

	// DEACTIVATE => deactivate the user from the server
	deactivateUser(userId: string): Observable<UsersModel> {
		const url = `${API.API_URL + API.API_VERSION + API.API_USERS}/${userId}/deactivate`;
		return this.http.put<UsersModel>(url, {});
	}

	deleteUser(userId: string): Observable<UsersModel> {
		const url = `${API.API_URL + API.API_VERSION + API.API_USERS}/${userId}`;
		return this.http.delete<UsersModel>(url, {});
	}

	getUsersList() {
		return this.usersList.filter( (user) => {
			return user.is_enabled;
		});
	}

	resetPassword(email: string): Observable<any> {
		const url = `${API.API_URL + API.API_VERSION}/reset-password`;
		return this.http.post(url, { 'ds_email': email });
	}

}
