import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, forkJoin, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { PayslipsModel } from '@app/core/models/payslips.model.ts';
import { QueryParamsModel } from '@app/content/pages/components/apps/e-commerce/_core/models/query-models/query-params.model';
import { QueryResultsModel } from '@app/content/pages/components/apps/e-commerce/_core/models/query-models/query-results.model';

import { AuthenticationService } from '@app/core/auth/authentication.service';

import { API } from '@env/environment';

@Injectable()
export class PayslipsService {

	private payslipsList: any[];

	constructor(
		private http: HttpClient,
		private auth: AuthenticationService
	) {}

	// CREATE =>  POST: add a new service to the server
	createPayslip(payslip: PayslipsModel): Observable<PayslipsModel> {
		const url = `${API.API_URL + API.API_VERSION + API.API_PAYSLIPS}`;
		return this.http.post<PayslipsModel>(url, payslip);
	}

	findPayslips(queryParams: QueryParamsModel): Observable<QueryResultsModel> {
		const url = `${API.API_URL + API.API_VERSION + API.API_PAYSLIPS}`;
		return this.http.get<QueryResultsModel>(url, {
            params: new HttpParams()
                .set('where', (queryParams.filter.where || ''))
                .set('page_index', queryParams.pageNumber.toString())
                .set('page_size', queryParams.pageSize.toString())
        }).pipe(
			tap((result: any) => { 
                if (result) {
                    this.payslipsList = result.data
                } else {
                    this.payslipsList = []
                }

                return this.payslipsList;
            })
		);
	}

	getPayslipById(payslipId: string): Observable<any> {
		const payslipsList = this.payslipsList.filter( payslip => payslip.id == payslipId)
		if ( payslipsList.length > 0 )
			return of(payslipsList[0]);
		else
			return of({});
	}

	updatePayslip(payslipId: string, payslip: PayslipsModel): Observable<any> {
		const url = `${API.API_URL + API.API_VERSION + API.API_PAYSLIPS}/${payslipId}`;
		return this.http.put(url, payslip);
	}

	// DELETE => delete the customer from the server
	deletePayslip(payslipId: string): Observable<PayslipsModel> {
		const url = `${API.API_URL + API.API_VERSION + API.API_PAYSLIPS}/${payslipId}`;
		return this.http.delete<PayslipsModel>(url);
	}

}
