import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, forkJoin, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { InvoicesSuppliersModel } from '@app/core/models/invoices-suppliers.model';
import { QueryParamsModel } from '@app/content/pages/components/apps/e-commerce/_core/models/query-models/query-params.model';
import { QueryResultsModel } from '@app/content/pages/components/apps/e-commerce/_core/models/query-models/query-results.model';

import { AuthenticationService } from '@app/core/auth/authentication.service';

import { API } from '@env/environment';

@Injectable()
export class InvoicesSuppliersService {

    private invoicesSupplierList: any[];

	constructor(
		private http: HttpClient,
		private auth: AuthenticationService
	) {}

	// CREATE =>  POST: add a new service to the server
	createInvoiceSupplier(invoicesupplier: InvoicesSuppliersModel): Observable<InvoicesSuppliersModel> {
		const url = `${API.API_URL + API.API_VERSION + API.API_INVOICES_SUPPLIERS}`;
		return this.http.post<InvoicesSuppliersModel>(url, invoicesupplier);
	}

	findInvoicesSuppliers(queryParams: QueryParamsModel): Observable<QueryResultsModel> {
		const url = `${API.API_URL + API.API_VERSION + API.API_INVOICES_SUPPLIERS}`;
		return this.http.get<QueryResultsModel>(url, {
            params: new HttpParams()
                .set('where', (queryParams.filter.where || ''))
                .set('page_index', queryParams.pageNumber.toString())
                .set('page_size', queryParams.pageSize.toString())
        }).pipe(
            tap((result: any) => { 
                if (result) {
                    this.invoicesSupplierList = result.data
                } else {
                    this.invoicesSupplierList = []
                }

                return this.invoicesSupplierList;
            })
		);
	}

    getInvoiceSupplierById(invoiceId: string): Observable<any> {
		const invoicesList = this.invoicesSupplierList.filter( invoice => invoice.id == invoiceId)
		if ( invoicesList.length > 0 )
			return of(invoicesList[0]);
		else
			return of({});
	}

	updateInvoiceSupplier(invoicesupplierId: string, invoicesupplier: InvoicesSuppliersModel): Observable<any> {
		delete invoicesupplier.cd_state;
        const url = `${API.API_URL + API.API_VERSION + API.API_INVOICES_SUPPLIERS}/${invoicesupplierId}`;
		return this.http.put(url, invoicesupplier);
	}

	// DELETE => delete the customer from the server
	deleteInvoiceSupplier(invoicesupplierId: string): Observable<InvoicesSuppliersModel> {
		const url = `${API.API_URL + API.API_VERSION + API.API_INVOICES_SUPPLIERS}/${invoicesupplierId}`;
		return this.http.delete<InvoicesSuppliersModel>(url);
	}

	comfirmInvoiceSupplier(invoicesupplierId: string): Observable<InvoicesSuppliersModel> {
		const url = `${API.API_URL + API.API_VERSION + API.API_INVOICES_SUPPLIERS}/${invoicesupplierId}/confirm`;
		return this.http.put<InvoicesSuppliersModel>(url, {});
	}

	viewInvoiceSupplier(invoicesupplierId: string): Observable<InvoicesSuppliersModel> {
		const url = `${API.API_URL + API.API_VERSION + API.API_INVOICES_SUPPLIERS}/${invoicesupplierId}/viewed`;
		return this.http.put<InvoicesSuppliersModel>(url, {});
	}

}
