import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';

@Pipe({
	name: 'localizedData',
  pure: false
})
export class LocalizedDataPipe implements PipeTransform {

	constructor(private translateService: TranslateService) {}

	transform(value: any, pattern?: string): any {
		const datePipe: DatePipe = new DatePipe(this.translateService.currentLang);

		let overridePattern = pattern;

		if ( !pattern ) {
			if ( this.translateService.currentLang == 'en' ) {
				overridePattern = 'MM/dd/yyyy';
			} else {
				overridePattern = 'dd/MM/yyyy';
			}
		}

		return datePipe.transform(value, overridePattern);
	}


}
