import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { API } from '@env/environment';

@Injectable()
export class UploadService {

	constructor(
		private http: HttpClient
	) {}

	upload(type: string, image: File) {
		const formData = new FormData();
		formData.append('file', image);
		const url = `${API.API_URL + API.API_VERSION + API.API_UPLOAD}?type=${type}`;
		return this.http.post(url, formData);
	}

}
