// IT
export const locale = {
	lang: 'it',
	data: {
		TRANSLATOR: {
			SELECT: 'Scegli la tua lingua',
		},
		MENU: {
			NEW: 'Nuovo',
			ACTIONS: 'Azioni',
			CREATE_POST: 'Crea un nuovo post',
			APPS: 'Apps',
			DASHBOARD: 'Pagina principale',
			USERS: 'Utenti',
			REPORTS: 'Resoconti',
			DOCUMENTS: 'Documenti',
			AGIBILITIES: 'Agibilità',
			INVOICES_SUPPLIERS: 'Fatture fornitori',
			INVOICES_CUSTOMERS: 'Fatture clienti',
			COMMUNICATIONS: 'Comunicazioni'
		},
		AUTH: {
			GENERAL: {
				OR: 'O',
				SUBMIT_BUTTON: 'Iscriviti',
				NO_ACCOUNT: 'Non hai un account?',
				SIGNUP_BUTTON: 'Signup',
				FORGOT_BUTTON: 'Password dimenticata',
				BACK_BUTTON: 'Indietro',
				PRIVACY: 'Privacy',
				LEGAL: 'Legal',
				CONTACT: 'Contatti',
			},
			LOGIN: {
				TITLE: 'Login Account',
				BUTTON: 'Accedi',
				HELP_EMAIL: 'Inserire email',
				HELP_PASSWORD: 'Inserire password',
				PASSWORD: 'Password',
			},
			FORGOT: {
				TITLE: 'Password dimenticata?',
				DESC: 'Inserisci la tua email per resettare la Password',
			},
			VALIDATION: {
				INVALID: '{{name}} non è valido',
				REQUIRED: '{{name}} è richiesto',
				MIN_LENGTH: 'La lunghezza minima del {{name}} è {{min}}',
				AGREEMENT_REQUIRED: 'È necessario accettare I termini e le condizioni contrattuali',
				NOT_FOUND: 'Il nome {{name}} non è stato trovato',
				INVALID_LOGIN: 'I dettagli della Login non sono corretti',
				PASSWORDS_SAME: 'Le password sono identiche',
				PASSWORDS_NOT_SAME: 'Passwords non sono uguali',
				PASSWORD_LEN: 'La Password deve essere lunga almeno 8 caratteri',
				PASSWORD_CONFIRM: 'Conferma password',
				PASSWORD_CONFIRM_HELP: 'Inserisci conferma password',
				PASSWORD_INSERT_CORRECTLY: 'Inserire la password correttamente!',
			}
		},
		FILTERS: {
			ALL: 'tutti',
			BYSTATUS: 'Filtra per status',
			BYTYPE: 'Filtra per tipo',
			ALLFIELDS: 'Cerca in tutti I campi',
			BUSINESS: 'Ristoranti',
			RIDER: 'Trasportatori',
			ONLINE: 'Online',
			OFFLINE: 'Offline',
			BYCATEGORY: 'Filtra per categoria'​​,
			BYCITY: 'Filtra per città',
			BYDATE: 'Filtra per data'
		},
		HEADTOOLS: {
			BACK: 'Indietro',
			SAVE: 'Salva',
			CLONE: 'Copia',
			ONLINE: 'On line',
			OFFLINE: 'Off line',
			ADD_NEW_DISH: 'Aggiungi un nuovo piatto',
			DELETE: 'Elimina'
		},
		GLOBAL_FIELDS_NAME: {
			EXEMPTION: 'Esenzione',
			BUSINESS_CODE: 'Codice Cliente',
			BUSINESS_CODE_HELP: 'Inserire il Codice Cliente',
			BUSINESS_NAME: 'Nome/Ragione sociale',
            BUSINESS_NAME_HELP: 'Inserire il nome del locale',
            BUSINESS_EVENT_REF: 'Riferimento evento',
            BUSINESS_EVENT_REF_HELP: 'Inserire riferimento evento (opzionale)',
			BUSINESS_PEC: 'PEC',
			BUSINESS_PEC_HELP: 'Inserire la PEC del locale',
			BUSINESS_PIVA: 'PIVA',
			BUSINESS_PIVA_HELP: 'Inserire la PIVA del locale',
			MESSAGE: 'Messaggio',
			MESSAGE_HELP: 'Inserisci il messaggio della fattura',
			PAYMENT_METHOD: 'Metodo di pagamento',
			PAYMENT_METHOD_HELP: 'Seleziona il metodo di pagamento',
			BONIFICO: 'Bonifico',
			CONTANTI: 'Contanti',
			ASSEGNO: 'Assegno',
			CUSTOMER: 'Cliente',
			SUPPLIER: 'Fornitore',
			NUMBER: 'Numero',
			AMOUNT: 'Importo',
			AMOUNT_HELP: 'Inserisci l\'importo della fattura',
			PLACE: 'Luogo',
			PLACE_HELP: 'Inserisci il luogo',
			SURNAME: 'Cognome',
			SURNAME_HELP: 'Inserisci il cognome',
			CATEGORY: 'Categoria',
			CATEGORY_HELP: 'Inserisci una categoria',
			SELECT_ATTACHMENT: 'Aggiorna allegato',
			USER: 'Utente',
			USER_HELP: 'Inserisci il nome dell\' utente',
			TITLE: 'Titolo',
			TITLE_HELP: 'Inserisci il titolo',
			ATTACHMENT: 'Allegato',
			FULLNAME: 'Nome e Cognome',
			BAND: 'Artista/Band',
			YES: 'Si',
			NO: 'No',
			CANCEL: 'Cancella',
			ICON: 'Icona',
			EMAIL: 'Email',
			PASSWORD: 'Password',
			EMAIL_HELP: 'Inserisci la tua email',
			PHONE: 'Phone',
			PHONE_HELP: 'Inserisci il tuo numero di telefono',
			LOCATION: 'Luogo',
			LOCATION_HELP: 'Gentilmente inserisci il tuo indirizzo',
            NAME: 'Nome',
            ARTIST: 'Artista',
            ARTIST_HELP: 'Seleziona l\'artista',
			NAME_HELP: 'Pe favore inserisci il tuo nome',
			GENDER: 'Sesso',
			AGE: 'Età',
			AGE_HELP: 'Inserisci l\'età',
			FIRST_NAME: 'Nome',
			FIRST_NAME_HELP: 'Inserisci il tuo nome',
			LAST_NAME: 'Cognome',
			LAST_NAME_HELP: 'Inserisci il tuo cognome',
			FULL_NAME: 'Nome e Cognome',
			TYPE: 'Tipo',
			DATE: 'Data',
			DATE_HELP: 'Scegli una data',
			STATUS: 'Stato',
			RESET_PASSWORD: 'Reset password',
			MALE: 'Uomo',
			FEMALE: 'Donna',
			NORECORDS_FOUND: 'Nessun record trovato',
			ACTIONS: 'Azioni',
			PRICE: 'Prezzo',
			PRICE_HELP: 'Inserisci il prezzo',
			DESCRIPTION: 'Descrizione',
			DESCRIPTION_HELP: 'Inserisci la descrizione',
            NOTES: 'Note',
            NOTES_HELP: 'Inserisci una nota (opzionale)',
            QUICK: 'Agibilità Veloce ?',
            QUICK_HELP: 'Seleziona questa spunta se vuoi inserire l\'agibilità direttamente nello stato CONFERMATA (documento agibilità obbligatorio)'
		},
		GLOBAL_ADDRESS_FIELDS: {
			ADDRESS_NAME: 'Indirizzo',
			ADDRESS: 'Indirizzo',
			CITY: 'Città',
			NUMBER: 'Numero',
			PROVINCE: 'Provincia',
			ZIP: 'Cap',
			ADDRESS_HELP: 'Inserisci il tuo indirizzo',
			CITY_HELP: 'Inserisci la tua città',
			NUMBER_HELP: 'Inserisci il tuo numero',
			PROVINCE_HELP: 'Inserisci la tua provincia',
			ZIP_HELP: 'Inserisci il tuo cap',
		},
		DASHBOARD: {},
		REPORTS: {
			REPORTS_LIST: 'Lista resoconti',
			NEW_REPORT: 'Nuovo resoconto',
			DETAILS: {
				REPORT_NAME_: 'Resoconto {{title}}',
			},
			UPDATE: {
				SUCCESS: 'Il report è stato aggiornato con successo',
				ERROR: "Il report non è stato aggiornato",
			},
			CREATE: {
				SUCCESS: 'Il report è stato creato con successo',
				ERROR: "Il report non è stato aggiornato",
			},
			DELETE: {
				TITLE: 'Cancella il resoconto',
				DESCRIPTION: 'Sei sicuro di voler cancellare questo resoconto?',
				WAIT_DESCRIPTION: 'Sto cancellando il resoconto...',
				MESSAGE: "Il resoconto è stato cancellato",
				YESBUTTON: 'Conferma',
				NOBUTTON: 'Cancella',
				ERROR: 'Cancellazione resoconto fallita'
			},
		},
		DOCUMENTS: {
			DOCUMENTS_LIST: 'Lista documenti',
			NEW_DOCUMENT: 'Nuovo documento',
			DETAILS: {
				DOCUMENT_NAME_: 'Documento {{title}}',
			},
			UPDATE: {
				SUCCESS: 'Il documento è stato aggiornato con successo',
				ERROR: "Il documento non è stato aggiornato",
			},
			CREATE: {
				SUCCESS: 'Il documento è stato creato con successo',
				ERROR: "Il documento non è stato aggiornato",
			},
			DELETE: {
				TITLE: 'Cancella il documento',
				DESCRIPTION: 'Sei sicuro di voler cancellare questo documento?',
				WAIT_DESCRIPTION: 'Sto cancellando il documento...',
				MESSAGE: "Il documento è stato cancellato",
				YESBUTTON: 'Conferma',
				NOBUTTON: 'Cancella',
				ERROR: 'Cancellazione documento fallita'
			},
		},
		COMMUNICATIONS: {
			COMMUNICATIONS_LIST: 'Lista comunicazioni',
			NEW_COMMUNICATION: 'Nuova comunicazione',
			DETAILS: {
				COMMUNICATION_NAME_: 'Comunicazione {{title}}',
			},
			UPDATE: {
				SUCCESS: 'La comunicazione è stata aggiornata con successo',
				ERROR: "La comunicazione non è stata aggiornato",
			},
			CREATE: {
				SUCCESS: 'La comunicazione è stata creata con successo',
				ERROR: "La comunicazione non è stata aggiornata",
			},
			DELETE: {
				TITLE: 'Cancella la comunicazione',
				DESCRIPTION: 'Sei sicuro di voler cancellare questa comunicazione?',
				WAIT_DESCRIPTION: 'Sto cancellando la comunicazione...',
				MESSAGE: "La comunicazione è stata cancellata",
				YESBUTTON: 'Conferma',
				NOBUTTON: 'Cancella',
				ERROR: 'Cancellazione comunicazione fallita'
			},
		},
		AGIBILITIES: {
			AGIBILITIES_LIST: 'Lista agibilità',
			NEW_AGIBILITY: 'Nuova agibilità',
            BLOCKS_AGIBILITY: 'Gestisci Blocco delle Richieste',
			DETAILS: {
				AGIBILITY_NAME_: 'Agibilità {{title}}',
			},
			UPDATE: {
				SUCCESS: 'L\'agibilità è stata aggiornata con successo',
				ERROR: "L\'agibilità non è stata aggiornata",
			},
			CREATE: {
				SUCCESS: 'L\'agibilità è stata creata con successo',
				ERROR: "L\'agibilità non è stata aggiornata",
			},
			DELETE: {
				TITLE: 'Cancella l\'agibilità',
				DESCRIPTION: 'Sei sicuro di voler cancellare questa agibilità?',
				WAIT_DESCRIPTION: 'Sto cancellando l\'agibilità...',
				MESSAGE: "L\'agibilità è stata cancellata",
				YESBUTTON: 'Conferma',
				NOBUTTON: 'Cancella',
				ERROR: 'Cancellazione agibilità fallita'
			},
			ACTIVATE: {
				TITLE: 'Attivare agibilità',
				DESCRIPTION: 'Sei sicuro di voler attivare questa agibilità?',
				WAIT_DESCRIPTION: 'Sto attivando l\'agibilità...',
				MESSAGE: 'L\'agibilità è stata attivata',
				YESBUTTON: 'Conferma',
				NOBUTTON: 'Cancella'
			},
			DEACTIVATE: {
				TITLE: 'Disattivare agibilità',
				DESCRIPTION: 'Sei sicuro di voler disattivare questa agibilità?',
				WAIT_DESCRIPTION: 'Sto disattivando l\'agibilità...',
				MESSAGE: 'L\'agibilità è stata disattivata',
				YESBUTTON: 'Conferma',
				NOBUTTON: 'Cancella',
				ERROR: 'Disattivazione agibilità fallita'
			},
		},
        AGIBILITIES_BLOCKS: {
            TITLE: 'Gestisci i periodi di blocco richiesta di agibilità',
            SAVE_ALL: 'Conferma e Salva i periodi',
            DATE_FROM: 'Dal',
            DATE_TO: 'Al',
            DELETE_INTERVAL_LABEL: 'Elimina periodo',
            REMOVE: {
				TITLE: 'Elimina periodo',
				DESCRIPTION: 'Sei sicuro di voler eliminare questo periodo di blocco?',
				WAIT_DESCRIPTION: 'Sto eliminando il periodo di blocco...',
				MESSAGE: 'Il periodo di blocco è stato eliminato. \nRicorda di salvare tutti i cambiamenti fatti premendo sul bottone in alto a destra.',
				YESBUTTON: 'Conferma',
				NOBUTTON: 'Annulla'
			},
            ADD: {
				TITLE: 'Aggiungi periodo',
				DESCRIPTION: 'Sei sicuro di voler aggiungere questo periodo di blocco?',
				WAIT_DESCRIPTION: 'Sto aggiungendo il periodo di blocco...',
				MESSAGE: 'Il periodo di blocco è stato aggiunto. \nRicorda di salvare tutti i cambiamenti fatti premendo sul bottone in alto a destra.',
				YESBUTTON: 'Conferma',
				NOBUTTON: 'Annulla'
			},
            SAVE: {
				TITLE: 'Salvataggio periodi di blocco richiesta di agibilità',
				DESCRIPTION: 'Sei sicuro di voler salvare questi periodi di blocco\n(gli attuali periodi verranno sovrascritti) ?',
				WAIT_DESCRIPTION: 'Sto salvando i periodi di blocco...',
				MESSAGE: 'I periodi di blocco sono stati aggiornati correttamente.',
				YESBUTTON: 'Conferma',
				NOBUTTON: 'Annulla'
			},
        },
		INVOICES_SUPPLIERS: {
			INVOICES_SUPPLIERS_LIST: 'Lista fatture fornitori',
			DELETE: {
				TITLE: 'Cancella la fattura',
				DESCRIPTION: 'Sei sicuro di voler cancellare questa fattura?',
				WAIT_DESCRIPTION: 'Sto cancellando la fattura...',
				MESSAGE: "La fattura è stata cancellata",
				YESBUTTON: 'Conferma',
				NOBUTTON: 'Cancella',
				ERROR: 'Cancellazione fattura fallita'
			},
			COMFIRM: {
				TITLE: 'Conferma la fattura',
				DESCRIPTION: 'Sei sicuro di voler confermare questa fattura?',
				WAIT_DESCRIPTION: 'Sto confermando la fattura...',
				MESSAGE: "La fattura è stata confermata",
				YESBUTTON: 'Conferma',
				NOBUTTON: 'Cancella',
				ERROR: 'Conferma fattura fallita'
			},
		},
		INVOICES_CUSTOMERS: {
			INVOICES_CUSTOMERS_LIST: 'Lista fatture clienti',
			NEW_INVOICE: 'Nuova fattura',
			DETAILS: {
				INVOICES_CUSTOMERS_NAME_: 'Fattura {{title}}',
			},
			UPDATE: {
				SUCCESS: 'La fattura è stato aggiornata con successo',
				ERROR: "La fattura non è stata aggiornato",
			},
			CREATE: {
				SUCCESS: 'La fattura è stato creato con successo',
				ERROR: "La fattura non è stato aggiornato",
			},
			DELETE: {
				TITLE: 'Cancella la fattura',
				DESCRIPTION: 'Sei sicuro di voler cancellare questa fattura?',
				WAIT_DESCRIPTION: 'Sto cancellando la fattura...',
				MESSAGE: "La fattura è stata cancellata",
				YESBUTTON: 'Conferma',
				NOBUTTON: 'Cancella',
				ERROR: 'Cancellazione fattura fallita'
			},
		},
		USERS: {
			NEW_USER: 'Nuovo Utente',
			USERS: 'Utenti',
			USERS_LIST: 'Lista utenti',
			FIELDS: {
				NAME: 'Nome',
				PHONE: 'Telefono',
				EMAIL: 'Email',
				ACTIONS: 'Azioni',
			},
			DETAILS: {
				NEW_USER: 'Nuovo utente',
				EDIT_USER_: 'Modifica utente {{fullname}}',
				ADDRESS_TITLE: 'Indirizzo',
			},
			UPDATE: {

				SUCCESS: 'L\'utente è stato aggiornato con successo',
				ERROR: "L'utente non è stato aggiornato",

			},
			INSERT: {

				SUCCESS: 'L\'utente è stato aggiunto con successo',
				ERROR: "L'utente non è stato aggiunto",

			},
			DELETE: {
				TITLE: 'Cancella utente',
				DESCRIPTION: 'Sei sicuro di voler cancellare questo utente?',
				WAIT_DESCRIPTION: 'Sto cancellando l\'utente...',
				MESSAGE: "L'utente è stato cancellato",
				YESBUTTON: 'Conferma',
				NOBUTTON: 'Cancella',
				ERROR: 'Cancellazione utenti fallita'
			},
			ACTIVATE: {
				TITLE: 'Attiva utente',
				DESCRIPTION: 'Sei sicuro di voler attivare questo utenti?',
				WAIT_DESCRIPTION: 'Sto attivando l\'utente...',
				MESSAGE: "L'utente è stato attivato",
				YESBUTTON: 'Conferma',
				NOBUTTON: 'Cancella',
				ERROR: 'Attivazione utente non riuscita'
			},
			DEACTIVATE: {
				TITLE: 'Disattivazione utente',
				DESCRIPTION: "Sei sicuro di voler disattivare l'utente?",
				WAIT_DESCRIPTION: "Sto disattivando l'utente...",
				MESSAGE: "L'utente è stato disattivato",
				YESBUTTON: 'Conferma',
				NOBUTTON: 'Cancella',
				ERROR: 'Disattivazione utenti non risucita'
			},
			RESET_PASSWORD: {
				TITLE: 'Resettare password utente',
				DESCRIPTION: 'Sei sicuro di voler resettare la password?',
				WAIT_DESCRIPTION: 'Sto inviando un email...',
				MESSAGE: 'Un email è stata inviata al tuo indirizzo',
				ERROR: 'Reset password fallita',
				YESBUTTON: 'Conferma',
				NOBUTTON: 'Cancella'
			},

		},
		PREPARE: {
			IS_MISSING: 'è obbligatorio',
		},
		PLACEHOLDERS: {
			USER: 'Inserisci il nome dell\'utente',
			BAND: 'Inserisci il nome della band',
			AGE: 'Inserire età',
			FULLNAME: 'Inserire nome completo',
			EMAIL: 'Inserire indirizzo email',
			SELECT_IMAGE: 'Scegliere immagine',
			PASSWORD: 'Inserire password',
			CONFIRM_PASSWORD: 'Inserire nuovamente password',
			DESCRIPTION: 'Inserire descrizione',
			NAME: 'Inserire nome',
			ZIP: 'Inserire cap',
			PROVINCE: 'Inserire provincia',
			CITY: 'Inserire città',
			CIVIC_NUMBER: 'Inserire numero civico',
			ADDRESS: 'Inserire indirizzo',
			VAT: 'Inserire VAT',
            BUSINESS_NAME: 'Inserire nome business',
            BUSINESS_EVENT_REF: 'Inserire riferimento evento',
			PHONE: 'Inserire numero di telefono',
			CUISINE_TYPES: 'Inserire tipo di cucina',
			FROM: 'Da',
			TO: 'A',
			DISCOUNT_PRICE: 'Prezzo di sconto',
			DATE: 'Scegliere una data',
			MF_PERCENTAGE: 'Inserire percentuale MF',
			SHIPPING_COST: 'Inserire costi di spedizione',
			MIN_ORDER: 'Inserire ordine minimo',
			PREPARATION_TIME: 'Inserire tempo di preparazione',
			ORDER_PREFIX: "Inserire prefisso d'ordine",
			MEETINGPOINT: "Scegliere punto d'incontro",
			FIRSTNAME: 'Inserire nome',
			LASTNAME: 'Inserire cognome',
			TITLE: 'Inserire titolo',
			TYPE: 'Inserire tipologia',
			PRICE: 'Inserire prezzo',
			SEARCH: 'Cerca',
			CUSTOMIZAZION: 'Inserire customizzazione',
			DISHES_CATEGORY: 'Inserire categoria piatti',
			DISHES: 'Inserire piatti',
			RIDER: 'Inserire trasportatore',
			PERCENTAGE_WITH_MF: 'Inserire percentuale con MF',
			PERCENTAGE_WITHOUT_MF: 'Inserire percentuale senza MF',
			DISH_NAME: 'Inserire nome piatto',
			DISH_CATEGORY: 'Inserire categoria piatto',
			DISH_PRICE: 'Inserire Prezzo piatto'​​,
			MESSAGE: 'Inserire messaggio',
			GENDER: 'Inserire sesso',
			SELECT_ATTACHMENT: 'Carica l\'allegato',
			CATEGORY: 'Inserisci la categoria',
			PLACE: 'Inserisci il luogo',
			AMOUNT: 'Inserisci l\'importo della fattura',
			BUSINESS_CODE: 'Inserire il Codice Cliente',
			BUSINESS_PEC: 'Inserire la PEC del locale',
			PIVA: 'Inserire la PIVA del locale',

		},
		UPLOAD: {
			UPLOAD_SUCCESS: 'Caricamento riuscito',
			UPLOAD_FAILED: 'Caricamento fallito',
			UPLOAD_MAX_SIZE: 'Dimensione massima 1MB'
		}
	}
};
