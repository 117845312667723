import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, forkJoin, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { InvoicesCustomersModel } from '@app/core/models/invoices-customers.model';
import { QueryParamsModel } from '@app/content/pages/components/apps/e-commerce/_core/models/query-models/query-params.model';
import { QueryResultsModel } from '@app/content/pages/components/apps/e-commerce/_core/models/query-models/query-results.model';

import { AuthenticationService } from '@app/core/auth/authentication.service';

import { API } from '@env/environment';

@Injectable()
export class InvoicesCustomersService {

	private invoicesCustomersList: any[];

	constructor(
		private http: HttpClient,
		private auth: AuthenticationService
	) {}

	// CREATE =>  POST: add a new service to the server
	createInvoiceCustomer(invoicecustomer: InvoicesCustomersModel): Observable<InvoicesCustomersModel> {
		const url = `${API.API_URL + API.API_VERSION + API.API_INVOICES_CUSTOMERS}`;
		return this.http.post<InvoicesCustomersModel>(url, invoicecustomer);
	}

	findInvoicesCustomers(queryParams: QueryParamsModel): Observable<QueryResultsModel> {
		const url = `${API.API_URL + API.API_VERSION + API.API_INVOICES_CUSTOMERS}`;
		return this.http.get<QueryResultsModel>(url, {
            params: new HttpParams()
                .set('where', (queryParams.filter.where || ''))
                .set('page_index', queryParams.pageNumber.toString())
                .set('page_size', queryParams.pageSize.toString())
        })
        .pipe(
            tap((result: any) => { 
                if (result) {
                    this.invoicesCustomersList = result.data
                } else {
                    this.invoicesCustomersList = []
                }

                return this.invoicesCustomersList;
            })
		)
	}

	getInvoiceCustomerById(invoiceId: string): Observable<any> {
		const invoicesCustomersList = this.invoicesCustomersList.filter( invoice => invoice.id == invoiceId)
		if ( invoicesCustomersList.length > 0 )
			return of(invoicesCustomersList[0]);
		else
			return of({});
	}

	updateInvoiceCustomer(invoicecustomerId: string, invoicecustomer: InvoicesCustomersModel): Observable<any> {
		const url = `${API.API_URL + API.API_VERSION + API.API_INVOICES_CUSTOMERS}/${invoicecustomerId}`;
		return this.http.put(url, invoicecustomer);
	}

	// DELETE => delete the customer from the server
	deleteInvoiceCustomer(invoicecustomerId: string): Observable<InvoicesCustomersModel> {
		const url = `${API.API_URL + API.API_VERSION + API.API_INVOICES_CUSTOMERS}/${invoicecustomerId}`;
		return this.http.delete<InvoicesCustomersModel>(url);
	}

}
