// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
	production: true,
	isMockEnabled: false, // You have to switch this, when your real back-end is done
	releaseStage: 'prod',
	firebase: {
    apiKey: "AIzaSyAfA_NF7vx-y2RxOuUWIiEkFfglrsLPYUI",
    authDomain: "showandco-cms-prod.firebaseapp.com",
    databaseURL: "https://showandco-cms-prod.firebaseio.com",
    projectId: "showandco-cms-prod",
    storageBucket: "showandco-cms-prod.appspot.com",
    messagingSenderId: "865285196179",
    appId: "1:865285196179:web:5b494b0b1bd6e26c"
	}
};

export const gmaps_api = {
	apikey: 'AIzaSyCJ5eHogNqaCJdAWO0PWPUR8lgh680daK8'
};

export const SC_PAYMENT_METHOD = {
	BONIFICO: 100,
	CONTANTI: 200,
	ASSEGNO: 300
};

export const SC_AGIBILITY_STATE = {
	WAITING: 100,
	CONFIRMED: 200,
	SUSPENDED: 300
};

export const API = {
  //API_URL: 'http://localhost:8080/api', // LOCAL
	//API_URL: 'https://api-dev.showandco.it/api', // DEV
	API_URL: 'https://api.showandco.it/api', // PROD
	API_VERSION: '/v1',
	API_ENDPOINT_LOGIN: '/auth',

	API_USERS: '/user',
	API_REPORTS: '/report',
	API_DOCUMENTS: '/document',
	API_PAYSLIPS: '/payslip',
	API_COMMUNICATIONS: '/communication',
	API_AGIBILITIES: '/agibility',
	API_INVOICES_SUPPLIERS: '/invoice-supplier',
	API_INVOICES_CUSTOMERS: '/invoice-customer',
	API_UPLOAD: '/attachment/upload',
};

export const ENPALS = [
    { code: '00', name: 'intermittenti'},
    { code: '001', name: 'insegnanti'},
    { code: '011', name: 'artisti lirici'},
    { code: '012', name: 'cantanti di musica leggera'},
    { code: '013', name: 'coristi e vocalisti'},
    { code: '014', name: 'maestri del coro, assistenti e aiuti del coro, suggeritori del coro'},

	{ code: '021', name: 'attori di prosa, mimi, allievi attori' },
	{ code: '022', name: 'attori cinematografici o di audiovisivi'},
    { code: '023', name: 'attori di doppiaggio'},
    { code: '024', name: 'attori di operetta'},
    { code: '025', name: 'attori di rivista, attori di varietà ed attrazioni'},
    { code: '026', name: 'artisti del circo' },
    { code: '027', name: 'attori di fotoromanzi'},
    { code: '028', name: 'suggeritori teatrali, cinematografici o di audiovisivi'},
    { code: '029', name: 'generici, figuranti speciali'},
    
    { code: '031', name: 'presentatori'},
	{ code: '032', name: 'disc-jockey' },
    { code: '033', name: 'animatori in strutture turistiche e di spettacolo'},

    { code: '041', name: 'registi teatrali, cinematografici o di audiovisivi'},
    { code: '042', name: 'aiuto registi teatrali, cinematografici o di audiovisivi'},
    { code: '043', name: 'sceneggiatori teatrali, cinematografici o di audiovisivi'},
    { code: '044', name: 'dialoghisti, adattatori cinetelevisivi o di audiovisivi'},
    { code: '045', name: 'direttori della fotografia, light designer'},
    { code: '046', name: 'soggettisti'},
    { code: '047', name: 'video-assist'},

	{ code: '051', name: 'direttori di produzione' },
    { code: '052', name: 'ispettori di produzione'},
    { code: '053', name: 'segretari di produzione'},
    { code: '054', name: 'segretari di edizione'},
    { code: '055', name: 'cassieri di produzione'},
    { code: '056', name: 'organizzatori generali, location manager'},
    { code: '057', name: 'responsabili di edizione della produzione cinematografica e televisiva'},
    { code: '058', name: 'casting director'},
    { code: '059', name: 'documentalisti audiovisivi'},

    { code: '061', name: 'direttori di scena'},
    { code: '062', name: 'direttori di doppiaggio'},
    { code: '063', name: 'assistenti di scena e di doppiaggio'},

	{ code: '071', name: 'direttori d’orchestra' },

    { code: '081', name: 'concertisti e solisti'},
    { code: '082', name: 'professori d’orchestra'},
	{ code: '083', name: 'orchestrali – musica dal vivo(anche di musica leggera)' },
    { code: '084', name: 'bandisti'},
    { code: '085', name: 'consulenti assistenti musicali'},

	{ code: '091', name: 'coreografi e assistenti coreografi'},
    { code: '092', name: 'ballerini e tersicorei' },
	{ code: '093', name: 'indossatori' },
	{ code: '094', name: 'figuranti lirici' },
	{ code: '095', name: 'figuranti di sala' },
	{ code: '096', name: 'fotomodelli' },
	{ code: '097', name: 'cubisti' },
    { code: '098', name: 'spogliarellisti'},

	{ code: '101', name: 'amministratori di formazioni artistiche' },
    { code: '102', name: 'amministratori di produzione cinematografica ed audiovisiva'},
    { code: '103', name: 'organizzatori teatrali'},

	{ code: '111', name: 'tecnici del montaggio, del suono e sound designer della produzione cinematografica' },
    { code: '112', name: 'tecnici del montaggio, del suono e sound designer del teatro'},
    { code: '113', name: 'tecnici del montaggio, del suono e sound designer di audiovisivi'},
	{ code: '114', name: 'tecnici del montaggio e del suono di altri settori dello spettacolo' },
    { code: '115', name: 'tecnici di sviluppo, stampa, luci, scena, effetti speciali ed altri tecnici della produzione cinematografica'},
    { code: '116', name: 'tecnici di luci, scena, effetti speciali ed altri tecnici del teatro'},
    { code: '117', name: 'tecnici di sviluppo, stampa, luci, scena, effetti speciali ed altri tecnici di audiovisivi'},
    { code: '118', name: 'tecnici di sviluppo, stampa, luci, scena, effetti speciali ed altri tecnici di fotoromanzi'},
    { code: '119', name: 'tecnici addetti alle manifestazioni di moda'},

    { code: '121', name: 'operatori di ripresa cinematografica o audiovisiva'},
    { code: '122', name: 'aiuto operatori di ripresa cinematografica o audiovisiva'},
    { code: '123', name: 'maestranze cinematografiche'},
    { code: '124', name: 'maestranze teatrali'},
    { code: '125', name: 'maestranze di imprese audiovisive'},
	{ code: '126', name: 'fotografi di scena' },

	{ code: '141', name: 'truccatori' },
	{ code: '142', name: 'parrucchieri' },

    { code: '500', name: 'lavoratori autonomi esercenti attività musicali'},

    { code: '821', name: 'imitatori, ipnotizzatori, illusionisti e prestigiatori'},
    { code: '822', name: 'marionettisti, burattinai'},
    { code: '823', name: 'acrobati, stuntman, contorsionisti'},
    { code: '824', name: 'maestri d\'armi'},
];
