import { BehaviorSubject, Observable, Subject, from, throwError, of } from 'rxjs';
import { map, catchError, tap, switchMap, mergeMapTo } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { AuthService } from 'ngx-auth';

import { TokenStorage } from './token-storage.service';
import { UtilsService } from '../services/utils.service';
import { AccessData } from './access-data';
import { Credential } from './credential';

import { AngularFireAuth } from 'angularfire2/auth';
import * as firebase from 'firebase/app';

import { API } from '@env/environment';

@Injectable()
export class AuthenticationService implements AuthService {
	//API_URL = 'api';
	API_URL = API.API_URL;
	API_VERSION = API.API_VERSION;
	API_ENDPOINT_LOGIN = '/auth';
	API_ENDPOINT_REFRESH = '/refresh';
	API_ENDPOINT_REGISTER = '/register';

	public onCredentialUpdated$: Subject<AccessData>;
	private _apiCredential: Credential;

	constructor(
		private http: HttpClient,
		private tokenStorage: TokenStorage,
		private util: UtilsService,
		public afAuth: AngularFireAuth) {
		this.onCredentialUpdated$ = new Subject();

		this.afAuth.auth.onAuthStateChanged((user: any) => {
			if (user) {
				user.getIdToken().then((data: any) => {
					console.log('onAuthStateChanged', 'getIdToken');
					tokenStorage.setAccessToken(data);
				});
			} else {
				this.logout(false);
			}
		});

		/*
		if (!this.getFirebaseUID()) {
			this.logout();
		}
		*/

	}

	/**
	 * Check, if user already authorized.
	 * @description Should return Observable with true or false values
	 * @returns {Observable<boolean>}
	 * @memberOf AuthService
	 */
	public isAuthorized(): Observable<boolean> {
		return this.tokenStorage.getAccessToken().pipe(map(token => !!token));
	}

	/**
	 * Get access token
	 * @description Should return access token in Observable from e.g. localStorage
	 * @returns {Observable<string>}
	 */
	public getAccessToken(): Observable<string> {
		return this.tokenStorage.getAccessToken();
	}

	/**
	 * Get access token
	 * @description Should return access token in Observable from e.g. localStorage
	 * @returns {Observable<string>}
	 */
	public getFirebaseUID(): string {
		return this.tokenStorage.getFirebaseUID();
	}

	public getFirebaseUserData(): any {
		return this.tokenStorage.getFirebaseUserData();
	}

	public getCredentials(): any {
		return this.tokenStorage.getCredentials();
	}

	/**
	 * Get access token
	 * @description Should return access token in Observable from e.g. localStorage
	 * @returns {Observable<string>}
	 */
	/*
   public getApiCredentials(): any {
	   return this._apiCredential;
   }
   */

	/**
	 * Get user roles
	 * @returns {Observable<any>}
	 */
	public getUserRoles(): Observable<any> {
		return this.tokenStorage.getUserRoles();
	}

	/**
	 * Function, that should perform refresh token verifyTokenRequest
	 * @description Should be successfully completed so interceptor
	 * can execute pending requests or retry original one
	 * @returns {Observable<any>}
	 */
	public refreshToken(): Observable<AccessData> {
		return this.tokenStorage.getRefreshToken().pipe(
			switchMap((refreshToken: string) => {
				return this.http.get<AccessData>(this.API_URL + this.API_ENDPOINT_REFRESH + '?' + this.util.urlParam(refreshToken));
			}),
			tap(this.saveAccessDataFirebase.bind(this)),
			catchError(err => {
				this.logout();
				return throwError(err);
			})
		);
	}

	/**
	 * Function, checks response of failed request to determine,
	 * whether token be refreshed or not.
	 * @description Essentialy checks status
	 * @param {Response} response
	 * @returns {boolean}
	 */
	public refreshShouldHappen(response: HttpErrorResponse): boolean {
		return response.status === 401;
	}

	/**
	 * Verify that outgoing request is refresh-token,
	 * so interceptor won't intercept this request
	 * @param {string} url
	 * @returns {boolean}
	 */
	public verifyTokenRequest(url: string): boolean {
		return url.endsWith(this.API_ENDPOINT_REFRESH);
	}

	private fromFirebaseAuthPromise(promise): Observable<any> {
		return from(promise);
	}

	/**
	 * Submit login request
	 * @param {Credential} credential
	 * @returns {Observable<any>}
	 */
	public loginFirebase(credential: Credential): Observable<any> {
		return this.fromFirebaseAuthPromise(
			this.afAuth.auth.signInWithEmailAndPassword(credential.email, credential.password)
		).pipe(
			map((result: any) => {
				this.tokenStorage.setCredentials(credential);
				//console.log('loginFirebase', result);
				/*
				return this.afAuth.auth.currentUser.getIdToken()
					.then((idTokenResult) => {
						console.log(idTokenResult);
						this.tokenStorage.setAccessToken(idTokenResult);
						this.saveAccessDataFirebase(result);//.bind(this)
						return result;
					});
					*/
				return result;
			}),
			tap(this.saveAccessDataFirebase.bind(this)),
			catchError(this.handleError('login', ['undefined']))
		);
	};

	/**
	 * Handle Http operation that failed.
	 * Let the app continue.
	 * @param operation - name of the operation that failed
	 * @param result - optional value to return as the observable result
	 */
	private handleError<T>(operation = 'operation', result?: any) {
		return (error: any): Observable<any> => {
			// TODO: send the error to remote logging infrastructure
			console.error(error); // log to console instead

			// Let the app keep running by returning an empty result.
			return from(result);
		};
	}

	/**
	 * Logout
	 */
	public logout(refresh?: boolean): void {
		this.tokenStorage.clear();
		this.afAuth.auth.signOut();
		if (refresh) {
			location.reload();
		}
	}

	public firebaseSignout() {
		this.afAuth.auth.signOut();
	}

	/**
	 * Save access data in the storage
	 * @private
	 * @param {AccessData} data
	 */
	/*
   private saveAccessDataApi(accessData: AccessData) {
	   //console.log('saveAccessData', accessData);
	   //console.log('NUOVO TOKEN', accessData.token);
	   // TODO: da modificare poi con firebase ecc...
	   accessData.accessToken = accessData.token;
	   accessData.roles = [];
	   accessData.roles.push(accessData.role.toUpperCase());
	   ////console.log('saveAccessData', accessData);
	   if (typeof accessData !== 'undefined') {
		   this.tokenStorage
			   .setAccessToken(accessData.accessToken)
			   .setRefreshToken(accessData.refreshToken)
			   .setUserRoles(accessData.roles);
		   this.onCredentialUpdated$.next(accessData);
	   }
   }
   */

	/*
	private setApiCredentialBasedOnRole(role: string) {
		//console.log('setApiCredentialBasedOnRole', role);
		let credential: any = {
			username: '',
			password: ''
		};
		switch (role) {
			case 'ADMIN':
				credential.username = 'admin@mf.it';
				credential.password = 'adminpwd';
				break;
			case 'BUSINESS':
				credential.username = 'biz@mf.net';
				credential.password = 'bizpwd';
				break;
			default: // USER
				credential.username = 'user@mf.net';
				credential.password = 'userpwd';
				break;
		}
		this._apiCredential = credential;
	}
	*/

	/**
	 * Save access data in the storage
	 * @private
	 * @param {AccessData} data
	 */
	private saveAccessDataFirebase(firebaseResponse: any) {
		console.log('saveAccessDataFirebase', firebaseResponse);

		this.afAuth.auth.currentUser.getIdTokenResult(false)
			.then((idTokenResult) => {
				console.log(idTokenResult);
				let accessData: any = {
					accessToken: idTokenResult.token,
					refreshToken: firebaseResponse.user.refreshToken,
					roles: [],
					firebaseUID: firebaseResponse.user.uid,
					displayName: firebaseResponse.user.displayName,
					email: firebaseResponse.user.email,
					phoneNumber: firebaseResponse.user.phoneNumber,
					emailVerified: firebaseResponse.user.emailVerified
				};
				if (idTokenResult.claims && idTokenResult.claims.role) {

					accessData.roles.push(idTokenResult.claims.role.toUpperCase());
					//this.setApiCredentialBasedOnRole(idTokenResult.claims.role.toUpperCase());

				} else {
					accessData.roles.push('USER');
					//this.setApiCredentialBasedOnRole('USER');
				}
				if (typeof accessData !== 'undefined') {
					this.tokenStorage
						.setFirebaseUserData(accessData)
						.setAccessToken(accessData.accessToken)
						.setRefreshToken(accessData.refreshToken)
						.setUserRoles(accessData.roles);
					this.onCredentialUpdated$.next(accessData);
				}

			})
			.catch((error) => {
				this.logout();
			});
	}

	/**
	 * Submit registration request
	 * @param {Credential} credential
	 * @returns {Observable<any>}
	 */
	public register(credential: Credential): Observable<any> {
		// dummy token creation
		credential = Object.assign({}, credential, {
			accessToken: 'access-token-' + Math.random(),
			refreshToken: 'access-token-' + Math.random(),
			roles: ['USER'],
		});
		return this.http.post(this.API_URL + this.API_ENDPOINT_REGISTER, credential)
			.pipe(catchError(this.handleError('register', []))
			);
	}

	/**
	 * Submit forgot password request
	 * @param {Credential} credential
	 * @returns {Observable<any>}
	 */
	public requestPassword(credential: Credential): Observable<any> {
		return this.http.get(this.API_URL + this.API_ENDPOINT_LOGIN + '?' + this.util.urlParam(credential))
			.pipe(catchError(this.handleError('forgot-password', []))
			);
	}

}
