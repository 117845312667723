export class QueryResultsModel {
	// fields
	data: any[];
	page_index: number;
    page_size: number;
    total_pages: number;
	total_items: number;

	constructor(data: any[] = [], page_index: number = 0, page_size: number = 0, total_pages: number = 0,  total_items: number = 0) {
		this.data = data;
        this.page_index = page_index;
        this.page_size = page_size;
        this.total_pages = total_pages;
		this.total_items = total_items;
	}
}
