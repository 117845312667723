import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, forkJoin, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DocumentsModel } from '@app/core/models/documents.model';
import { QueryParamsModel } from '@app/content/pages/components/apps/e-commerce/_core/models/query-models/query-params.model';
import { QueryResultsModel } from '@app/content/pages/components/apps/e-commerce/_core/models/query-models/query-results.model';

import { AuthenticationService } from '@app/core/auth/authentication.service';

import { API } from '@env/environment';

@Injectable()
export class DocumentsService {

	private documentsList: any[];

	constructor(
		private http: HttpClient,
		private auth: AuthenticationService
	) {}

	// CREATE =>  POST: add a new service to the server
	createDocument(document: DocumentsModel): Observable<DocumentsModel> {
		const url = `${API.API_URL + API.API_VERSION + API.API_DOCUMENTS}`;
		return this.http.post<DocumentsModel>(url, document);
	}

	findDocuments(queryParams: QueryParamsModel): Observable<QueryResultsModel> {
		const url = `${API.API_URL + API.API_VERSION + API.API_DOCUMENTS}`;
		return this.http.get<QueryResultsModel>(url, {
            params: new HttpParams()
                .set('where', (queryParams.filter.where || ''))
                .set('page_index', queryParams.pageNumber.toString())
                .set('page_size', queryParams.pageSize.toString())
        }).pipe(
			tap((result: any) => { 
                if (result) {
                    this.documentsList = result.data
                } else {
                    this.documentsList = []
                }

                return this.documentsList;
            })
		);
	}

	getDocumentById(documentId: string): Observable<any> {
		const documentsList = this.documentsList.filter( document => document.id == documentId)
		if ( documentsList.length > 0 )
			return of(documentsList[0]);
		else
			return of({});
	}

	updateDocument(documentId: string, document: DocumentsModel): Observable<any> {
		const url = `${API.API_URL + API.API_VERSION + API.API_DOCUMENTS}/${documentId}`;
		return this.http.put(url, document);
	}

	// DELETE => delete the customer from the server
	deleteDocument(documentId: string): Observable<DocumentsModel> {
		const url = `${API.API_URL + API.API_VERSION + API.API_DOCUMENTS}/${documentId}`;
		return this.http.delete<DocumentsModel>(url);
	}

}
