import { Injectable } from '@angular/core';
import { Observable, combineLatest, BehaviorSubject, of } from 'rxjs';

import { UsersService } from '@app/core/services/users.service';
import { UsersModel } from '@app/core/models/users.model';

@Injectable({
	providedIn: 'root'
})
export class StaticCollectionsService {
	//private usersObserv$: Observable<UsersModel[]>;

	public collectionReady: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	public usersList;

	constructor(
		private usersService: UsersService
	) {
			this.fillStaticCollection();
	}

	fillStaticCollection() {
        
		this.collectionReady.next(false);

		    this.usersService.getUsers()
            .subscribe( data => {
                this.usersList = data;
				console.log('fillStaticCollection', this.usersList);
				this.collectionReady.next(true);
            });

            /*
            combineLatest(this.usersObserv$,
				(usersObserv) => ({ usersObserv }))
				.subscribe(pair => {
					this.usersList = pair.usersObserv;
					console.log('fillStaticCollection', this.usersList);
					this.collectionReady.next(true);
				});
                */
	}

    getUserNameById(userId: string): string {
		const userFind = this.usersList.filter(user => user.id === userId);
		if (userFind.length > 0) {
			return userFind[0]['ds_fullname'];
		} else {
			return '';
		}
	}

    getUserById(userId: string): Observable<any> {
		const userFind = this.usersList.filter(user => user.id === userId);
		if (userFind.length > 0) {
			return of(userFind[0]);
		} else {
			return of({});
		}
	}

    getUsersList() {
		return this.usersList.filter( (user) => {
			return user.is_enabled;
		});
	}
}
