import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, forkJoin, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CommunicationsModel } from '@app/core/models/communications.model.ts';
import { QueryParamsModel } from '@app/content/pages/components/apps/e-commerce/_core/models/query-models/query-params.model';
import { QueryResultsModel } from '@app/content/pages/components/apps/e-commerce/_core/models/query-models/query-results.model';

import { AuthenticationService } from '@app/core/auth/authentication.service';

import { API } from '@env/environment';

@Injectable()
export class CommunicationsService {

	private communicationsList: any[];

	constructor(
		private http: HttpClient,
		private auth: AuthenticationService
	) {}

	// CREATE =>  POST: add a new service to the server
	createCommunication(communication: CommunicationsModel): Observable<CommunicationsModel> {
		const url = `${API.API_URL + API.API_VERSION + API.API_COMMUNICATIONS}`;
		return this.http.post<CommunicationsModel>(url, communication);
	}

	findCommunications(queryParams: QueryParamsModel): Observable<QueryResultsModel> {
		const url = `${API.API_URL + API.API_VERSION + API.API_COMMUNICATIONS}`;
		return this.http.get<QueryResultsModel>(url, {
            params: new HttpParams()
                //.set('where', (queryParams.filter.where || ''))
                .set('page_index', queryParams.pageNumber.toString())
                .set('page_size', queryParams.pageSize.toString())
        }).pipe(
			tap((result: any) => { 
                if (result) {
                    this.communicationsList = result.data
                } else {
                    this.communicationsList = []
                }

                return this.communicationsList;
            })
		);
	}

	getCommunicationById(communicationId: string): Observable<any> {
		const communicationsList = this.communicationsList.filter( communication => communication.id == communicationId)
		if ( communicationsList.length > 0 )
			return of(communicationsList[0]);
		else
			return of({});
	}

	updateCommunication(communicationId: string, communication: CommunicationsModel): Observable<any> {
		const url = `${API.API_URL + API.API_VERSION + API.API_COMMUNICATIONS}/${communicationId}`;
		return this.http.put(url, communication);
	}

	// DELETE => delete the customer from the server
	deleteCommunication(communicationId: string): Observable<CommunicationsModel> {
		const url = `${API.API_URL + API.API_VERSION + API.API_COMMUNICATIONS}/${communicationId}`;
		return this.http.delete<CommunicationsModel>(url);
	}

}
