import { environment } from '@env/environment';

// create a factory which will return the bugsnag error handler
/*
export function errorHandlerFactory() {
  return new BugsnagErrorHandler(bugsnagClient)
}
*/

import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
//import { HttpClientModule } from '@angular/common/http';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MadMaxInterceptor } from './core/services/madmax.interceptor';

import 'hammerjs';


import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { AuthenticationModule } from './core/auth/authentication.module';
import { NgxPermissionsModule } from 'ngx-permissions';

import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';
//import { FakeApiService } from './fake-api/fake-api.service';

import { LayoutModule } from './content/layout/layout.module';
import { PartialsModule } from './content/partials/partials.module';
import { CoreModule } from './core/core.module';
import { AclService } from './core/services/acl.service';
import { LayoutConfigService } from './core/services/layout-config.service';
import { MenuConfigService } from './core/services/menu-config.service';
import { PageConfigService } from './core/services/page-config.service';
//import { UserService } from './core/services/users.service';
import { UtilsService } from './core/services/utils.service';
import { ClassInitService } from './core/services/class-init.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { GestureConfig, MatProgressSpinnerModule } from '@angular/material';
import { OverlayModule } from '@angular/cdk/overlay';

import { MessengerService } from './core/services/messenger.service';
import { ClipboardService } from './core/services/clipboard.sevice';

import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { LayoutConfigStorageService } from './core/services/layout-config-storage.service';
import { LogsService } from './core/services/logs.service';
import { QuickSearchService } from './core/services/quick-search.service';
import { SubheaderService } from './core/services/layout/subheader.service';
import { HeaderService } from './core/services/layout/header.service';
import { MenuHorizontalService } from './core/services/layout/menu-horizontal.service';
import { MenuAsideService } from './core/services/layout/menu-aside.service';
import { LayoutRefService } from './core/services/layout/layout-ref.service';
import { SplashScreenService } from './core/services/splash-screen.service';
import { DataTableService } from './core/services/datatable.service';

import { UploadService } from '@app/core/services/upload.service';
import { ReportsService } from '@app/core/services/reports.service';
import { DocumentsService } from '@app/core/services/documents.service';
import { PayslipsService } from '@app/core/services/payslips.service';
import { CommunicationsService } from '@app/core/services/communications.service';
import { AgibilitiesService } from '@app/core/services/agibilities.service';
import { AgibilitiesIntervalsService } from '@app/core/services/agibilities-intervals.service';
import { InvoicesSuppliersService } from '@app/core/services/invoices-suppliers.service';
import { InvoicesCustomersService } from '@app/core/services/invoices-customers.service';
// Static Collections
import { StaticCollectionsService } from '@app/core/services/static-collections.service';
//
import { HttpUtilsService } from '@app/content/pages/components/apps/e-commerce/_core/utils/http-utils.service';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
	// suppressScrollX: true
};

// Firebase
import { AngularFireModule } from 'angularfire2';
import { AngularFirestoreModule } from 'angularfire2/firestore';
import { AngularFireAuthModule } from 'angularfire2/auth';
import { AngularFireMessagingModule } from '@angular/fire/messaging';

import { UsersService } from '@app/core/services/users.service';
import { StoreModule } from '@ngrx/store';
import { reducers, metaReducers } from './reducers';
import { EffectsModule } from '@ngrx/effects';
import { AppEffects } from './app.effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { FormsModule,ReactiveFormsModule } from '@angular/forms';

@NgModule({
	declarations: [AppComponent],
	imports: [
    BrowserModule, 
    FormsModule, 
    ReactiveFormsModule, 
		BrowserAnimationsModule,

		AppRoutingModule,
		HttpClientModule,
		//environment.isMockEnabled ? HttpClientInMemoryWebApiModule.forRoot(FakeApiService) : [],
		LayoutModule,
		PartialsModule,
		CoreModule,
		OverlayModule,
		AuthenticationModule,
		NgxPermissionsModule.forRoot(),
		NgbModule,
		TranslateModule.forRoot(),
		MatProgressSpinnerModule,
		AngularFireModule.initializeApp(environment.firebase),
	  AngularFirestoreModule, // imports firebase/firestore, only needed for database features
	  AngularFireAuthModule, // imports firebase/auth, only needed for auth features
		AngularFireMessagingModule, StoreModule.forRoot(reducers, { metaReducers }), EffectsModule.forRoot([AppEffects]), StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
	],
	providers: [
		//{ provide: ErrorHandler, useFactory: errorHandlerFactory },
		AclService,
		LayoutConfigService,
		LayoutConfigStorageService,
		LayoutRefService,
		MenuConfigService,
		PageConfigService,
		//UserService,
		UtilsService,
		ClassInitService,
		MessengerService,
		ClipboardService,
		LogsService,
		QuickSearchService,
		DataTableService,
		{ provide: HTTP_INTERCEPTORS, useClass: MadMaxInterceptor, multi: true },
		SplashScreenService,
		{
			provide: PERFECT_SCROLLBAR_CONFIG,
			useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
		},
		UsersService,
		StaticCollectionsService,
    UploadService,
    ReportsService,
    DocumentsService,
		PayslipsService,
    CommunicationsService,
    AgibilitiesService,
    AgibilitiesIntervalsService,
    InvoicesSuppliersService,
    InvoicesCustomersService,
		//MapsAPILoader,
		HttpUtilsService,
		// template services
		SubheaderService,
		HeaderService,
		MenuHorizontalService,
		MenuAsideService,
		{
			provide: HAMMER_GESTURE_CONFIG,
			useClass: GestureConfig
		}
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
