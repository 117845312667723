import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, forkJoin, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AgibilitiesModel } from '@app/core/models/agibilities.model.ts';
import { QueryParamsModel } from '@app/content/pages/components/apps/e-commerce/_core/models/query-models/query-params.model';
import { QueryResultsModel } from '@app/content/pages/components/apps/e-commerce/_core/models/query-models/query-results.model';

import { AuthenticationService } from '@app/core/auth/authentication.service';

import { API } from '@env/environment';

@Injectable()
export class AgibilitiesService {

	private agibilitiesList: any[];

	constructor(
		private http: HttpClient,
		private auth: AuthenticationService
    ) {}

    createAgibility(agibility: AgibilitiesModel) {
        const url = `${API.API_URL + API.API_VERSION + API.API_AGIBILITIES}`;
		return this.http.post(url, agibility);
    }

	findAgibilities(queryParams: QueryParamsModel): Observable<QueryResultsModel> {
		const url = `${API.API_URL + API.API_VERSION + API.API_AGIBILITIES}`;
		return this.http.get<QueryResultsModel>(url, {
            params: new HttpParams()
                .set('where', (queryParams.filter.where || ''))
                .set('page_index', queryParams.pageNumber.toString())
                .set('page_size', queryParams.pageSize.toString())
        }).pipe(
			tap((result: any) => { 
                    if (result) {
                        this.agibilitiesList = result.data
                    } else {
                        this.agibilitiesList = []
                    }
    
                    return this.agibilitiesList;
                })
		);
	}

	getAgibilityById(agibilityId: string): Observable<any> {
		const agibilitiesList = this.agibilitiesList.filter( agibility => agibility.id == agibilityId)
		if ( agibilitiesList.length > 0 )
			return of(agibilitiesList[0]);
		else
			return of({});
	}

	updateAgibility(agibilityId: string, agibility: AgibilitiesModel): Observable<any> {
		const url = `${API.API_URL + API.API_VERSION + API.API_AGIBILITIES}/${agibilityId}`;
		return this.http.put(url, agibility);
	}

	// DELETE => delete the customer from the server
	deleteAgibility(agibilityId: string): Observable<AgibilitiesModel> {
		const url = `${API.API_URL + API.API_VERSION + API.API_AGIBILITIES}/${agibilityId}`;
		return this.http.delete<AgibilitiesModel>(url);
	}

	confirmAgibility(agibilityId: string): Observable<any> {
		const url = `${API.API_URL + API.API_VERSION + API.API_AGIBILITIES}/${agibilityId}/confirm`;
		return this.http.put(url, null);
	}

	suspendAgibility(agibilityId: string): Observable<any> {
		const url = `${API.API_URL + API.API_VERSION + API.API_AGIBILITIES}/${agibilityId}/suspend`;
		return this.http.put(url, null);
	}



}
