import { ConfigModel } from '../core/interfaces/config';

export class PagesConfig implements ConfigModel {
	public config: any = {};

	constructor() {
		this.config = {
			'/': {
				page: {
					title: 'Dashboard',
					desc: 'Latest updates and statistic charts'
				}
			},
			'users': {
				page: { title: 'UTENTI', desc: ''}
			},
			'reports': {
				page: { title: 'RESOCONTI', desc: ''}
			},
			'documents': {
				page: { title: 'DOCUMENTI', desc: ''}
			},
			'agibilities': {
				page: { title: 'AGIBILITA\'', desc: ''}
			},
			'invoices-suppliers': {
				page: { title: 'FATTURE FORNITORI', desc: ''}
			},
			'invoices-customers': {
				page: { title: 'FATTURE CLIENTI', desc: ''}
			},
			'user-edit': {
				page: { title: 'users edit', desc: ''}
			},
			'report-edit': {
				page: { title: 'reports edit', desc: ''}
			},
			'document-edit': {
				page: { title: 'documents edit', desc: ''}
			},
			'agibility-edit': {
				page: { title: 'agibilities edit', desc: ''}
			},
			'invoice-customer-edit edit': {
				page: { title: 'invoices-customers', desc: ''}
			},



			404: {
				page: { title: '404 Not Found', desc: '', subheader: false }
			}
		};
	}
}
