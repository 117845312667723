import { Injectable } from '@angular/core';
import {
	HttpInterceptor,
	HttpRequest,
	HttpResponse,
	HttpHandler,
	HttpEvent,
	HttpErrorResponse,
	HttpSentEvent,
	HttpHeaderResponse,
	HttpProgressEvent,
	HttpUserEvent
} from '@angular/common/http';

import { Observable, throwError, BehaviorSubject, from } from 'rxjs';
import { map, tap, catchError, filter, take, switchMap, finalize } from 'rxjs/operators';
import { AuthenticationService } from "../auth/authentication.service";
import { Credential } from '@app/core/auth/credential';
import { TokenStorage } from '@app/core/auth/token-storage.service';

import { AngularFireAuth } from 'angularfire2/auth';
import * as firebase from 'firebase/app';

@Injectable()
export class MadMaxInterceptor implements HttpInterceptor {
	isRefreshingToken: boolean = false;
	tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

	constructor(
		private auth: AuthenticationService,
		public afAuth: AngularFireAuth,
		private tokenStorage: TokenStorage,
	) { }

	private setHeaderToRequest(request: HttpRequest<any>): HttpRequest<any> {
		const token: string = <string>localStorage.getItem('accessToken');
		if (token) { request = request.clone({ headers: request.headers.set('X-SC-API-KEY', token) }); }
		request = request.clone({ headers: request.headers.set('X-SC-OS', 'web') });
		request = request.clone({ headers: request.headers.set('X-SC-CLIENT', 'cms') });
		request = request.clone({ headers: request.headers.set('X-SC-LANG', 'it') });
		return request;
	}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any> | any> {

		return next.handle(this.setHeaderToRequest(request))
			.pipe(
				catchError((err: any) => {
					if (err instanceof HttpErrorResponse) {
						console.log('MadMaxInterceptor', 'ERRORE =>', err.status, err);
						if (err.status == 401) { // Refresh Token
							//this.auth.logout(true);
							return this.handle401Error(request, next);
						} else {
							return throwError(err);
						}
					}
					return throwError(err);
				}),
		);
	}

	private fromFirebaseAuthPromise(promise): Observable<any> {
		return from(promise);
	}

	private handle401Error(request: HttpRequest<any>, next: HttpHandler) {

		console.log(this.isRefreshingToken);

		if (!this.isRefreshingToken) {
			this.isRefreshingToken = true;

			// Reset here so that the following requests wait until the token
			// comes back from the refreshToken call.
			this.tokenSubject.next(null);
			if ( this.afAuth.auth.currentUser ) {
				return this.fromFirebaseAuthPromise(
					this.afAuth.auth.currentUser.getIdToken()
				).pipe(
					switchMap((token: any) => {
						console.log('getToken', token);
						this.tokenSubject.next(token);
						this.tokenStorage.setAccessToken(token);
						return next.handle(this.setHeaderToRequest(request));
					}),
					catchError((error: any) => {
						console.log();
						this.isRefreshingToken = false;
						this.auth.logout();
						return throwError(error);
					})
				)
			} else {
				this.isRefreshingToken = false;
				this.auth.logout(true);
				return throwError('error');
			}


			//location.reload(true);

		} else {
			this.isRefreshingToken = false;

			console.log('isRefreshingToken');

			return this.tokenSubject
				.pipe(filter(token => token != null),
					take(1),
					switchMap(token => {
						return next.handle(this.setHeaderToRequest(request));
					}));
		}
	}
}
