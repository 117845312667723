import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, forkJoin, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ReportsModel } from '@app/core/models/reports.model.ts';
import { QueryParamsModel } from '@app/content/pages/components/apps/e-commerce/_core/models/query-models/query-params.model';
import { QueryResultsModel } from '@app/content/pages/components/apps/e-commerce/_core/models/query-models/query-results.model';

import { AuthenticationService } from '@app/core/auth/authentication.service';

import { API } from '@env/environment';

@Injectable()
export class ReportsService {

	private reportsList: any[];

	constructor(
		private http: HttpClient,
		private auth: AuthenticationService
	) {}

	// CREATE =>  POST: add a new service to the server
	createReport(report: ReportsModel): Observable<ReportsModel> {
		const url = `${API.API_URL + API.API_VERSION + API.API_REPORTS}`;
		return this.http.post<ReportsModel>(url, report);
	}

	findReports(queryParams: QueryParamsModel): Observable<QueryResultsModel> {
		const url = `${API.API_URL + API.API_VERSION + API.API_REPORTS}`;
		return this.http.get<QueryResultsModel>(url, {
            params: new HttpParams()
                .set('where', (queryParams.filter.where || ''))
                .set('page_index', queryParams.pageNumber.toString())
                .set('page_size', queryParams.pageSize.toString())
        }).pipe(
			tap((result: any) => { 
                if (result) {
                    this.reportsList = result.data
                } else {
                    this.reportsList = []
                }

                return this.reportsList;
            })
		);
	}

	getReportById(reportId: string): Observable<any> {
		const reportsList = this.reportsList.filter( report => report.id == reportId)
		if ( reportsList.length > 0 )
			return of(reportsList[0]);
		else
			return of({});
	}

	updateReport(reportId: string, report: ReportsModel): Observable<any> {
		const url = `${API.API_URL + API.API_VERSION + API.API_REPORTS}/${reportId}`;
		return this.http.put(url, report);
	}

	// DELETE => delete the customer from the server
	deleteReport(reportId: string): Observable<ReportsModel> {
		const url = `${API.API_URL + API.API_VERSION + API.API_REPORTS}/${reportId}`;
		return this.http.delete<ReportsModel>(url);
	}

}
