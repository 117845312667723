import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, forkJoin, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AgibilitiesIntervalModel } from '@app/core/models/agibilities-intervals.model';
import { QueryParamsModel } from '@app/content/pages/components/apps/e-commerce/_core/models/query-models/query-params.model';
import { QueryResultsModel } from '@app/content/pages/components/apps/e-commerce/_core/models/query-models/query-results.model';

import { AuthenticationService } from '@app/core/auth/authentication.service';

import { API } from '@env/environment';

@Injectable()
export class AgibilitiesIntervalsService {

	private intervalList: any[];

	constructor(
		private http: HttpClient,
		private auth: AuthenticationService
    ) {}

    createAgibilityIntervals(intervals: AgibilitiesIntervalModel[]) {
        const url = `${API.API_URL + API.API_VERSION + API.API_AGIBILITIES + '/intervals'}`;
		return this.http.post(url, intervals);
    }

	findAgibilitiesIntervals(): Observable<QueryResultsModel> {
		const url = `${API.API_URL + API.API_VERSION + API.API_AGIBILITIES + '/intervals'}`;
		return this.http.get<QueryResultsModel>(url, {
            params: new HttpParams()
                //.set('where', (queryParams.filter.where || ''))
                //.set('page_index', queryParams.pageNumber.toString())
                //.set('page_size', queryParams.pageSize.toString())
        }).pipe(
			tap((result: any) => { 
                    if (result) {
                        this.intervalList = result
                    } else {
                        this.intervalList = []
                    }
    
                    return this.intervalList;
                })
		);
	}
}
